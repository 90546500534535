import IconTextDocumentAlt from '@/Icons/IconTextDocumentAlt'
import IconHome from '@/Icons/IconHome'
import IconBox from '@/Icons/IconBox'
import IconUserSquare from '@/Icons/IconUserSquare'
import IconDocuments from '@/Icons/IconDocuments'
import IconSettings from '@/Icons/IconSettings'
import IconVisa from '@/Icons/IconVisa'
import {
  IconCoin,
  IconHelp,
  IconLayoutGrid,
  IconListDetails,
  IconReportMoney,
  IconRobot,
  IconSpeakerphone,
  IconSubtask,
  IconUserDollar,
} from '@tabler/icons-react'
import { XelaColor } from '@/XelaReact'
import IconReport from '@/Icons/IconReport'
import IconB2BHub from '@/Icons/IconB2BHub'
import { IconCreditCard } from '@/Icons/IconCreditCard'
import React from 'react'

export const SIDEBAR_ITEMS = [
  {
    type: 'item',
    label: 'Home',
    route: '/dashboard',
    icon: <IconHome width="18" height="18" viewBox="0 0 16 16" />,
    features: ['basic_dashboard'],
    permissions: ['*'],
  },
  {
    type: 'item',
    label: 'Course Wiz',
    route: '/v2/courses',
    icon: <IconBox width="18" height="17" viewBox="0 0 16 17"></IconBox>,
    features: ['courses'],
    permissions: ['course_wizard_access_course_wizard', 'course_wizard_delete_course', 'course_wizard_edit_courses'],
  },
  {
    type: 'item',
    label: 'Announcements',
    route: '/announcements',
    icon: <IconSpeakerphone color={XelaColor.Blue3} stroke={1.2} size={22} fill={XelaColor.Blue12}></IconSpeakerphone>,
    features: ['basic_dashboard'],
    permissions: ['announcement_module_manage_announcement'],
  },
  {
    type: 'divider',
    label: 'Study Abroad',
    features: ['lead', 'counselling', 'admission', 'visa'],
    permissions: [
      'lead_module_manage_lead',
      'lead_module_branch_leads',
      'lead_module_all_leads',
      'lead_module_change_lead_manager',
      'lead_module_import_leads',
      'lead_module_delete_lead',
      'lead_module_unlock_lead',
      'lead_module_change_lead_branch',
      'lead_module_access_lead_documents',
      'lead_module_create_document',
      'lead_module_access_notes',
      'lead_module_edit_note',
      'lead_module_delete_note',
      'lead_module_delete_document',
      'lead_module_edit_payment',
      'lead_module_manage_payment',
      'lead_module_edit_payment_records',
      'lead_module_generate_payment_invoices',
      'lead_module_delete_payment',
      'counselling_module_manage_lead',
      'counselling_module_branch_leads',
      'counselling_module_all_leads',
      'lead_module_delete_lead',
      'lead_module_unlock_lead',
      'lead_module_change_lead_manager',
      'admission_module_manage_lead',
      'admission_module_branch_leads',
      'admission_module_all_leads',
      'lead_module_delete_lead',
      'lead_module_unlock_lead',
      'lead_module_change_lead_manager',
      'visa_module_manage_lead',
      'visa_module_branch_leads',
      'visa_module_all_leads',
      'lead_module_delete_lead',
      'lead_module_unlock_lead',
      'lead_module_change_lead_manager',
    ],
  },
  {
    type: 'item',
    label: 'Leads',
    route: '/leads',
    icon: <IconTextDocumentAlt width="18" height="19" viewBox="0 0 16 17" />,
    features: ['lead'],
    permissions: ['lead_module_manage_lead', 'lead_module_branch_leads', 'lead_module_all_leads'],
  },
  {
    type: 'item',
    label: 'Counsellings',
    route: '/counsellings',
    icon: <IconUserSquare height="18" width="18" viewBox="0 0 17 17"></IconUserSquare>,
    features: ['counselling'],
    permissions: ['counselling_module_manage_lead', 'counselling_module_branch_leads', 'counselling_module_all_leads'],
  },
  {
    type: 'item',
    label: 'Admissions',
    route: '/admissions',
    icon: <IconDocuments height="18" width="18" viewBox="0 0 16 16"></IconDocuments>,
    features: ['admission'],
    permissions: ['admission_module_manage_lead', 'admission_module_branch_leads', 'admission_module_all_leads'],
  },
  {
    type: 'item',
    label: 'Visas',
    route: '/visas',
    icon: <IconVisa height="18" width="18" viewBox="0 0 15 12"></IconVisa>,
    features: ['visa'],
    permissions: ['visa_module_manage_lead', 'visa_module_branch_leads', 'visa_module_all_leads'],
  },

  {
    type: 'item',
    label: 'Post Visa',
    route: '/post-visas',
    icon: <IconCreditCard></IconCreditCard>,
    features: ['visa'],
    permissions: ['post_visa_module_all_leads'],
  },
  {
    type: 'divider',
    label: 'Visitor Visa',
    features: ['visitor_lead'],
    permissions: [
      'visitor_lead_module_manage_lead',
      'visitor_lead_module_branch_leads',
      'visitor_lead_module_all_leads',
    ],
  },
  {
    type: 'item',
    label: 'Visitor Leads',
    route: '/visitor-leads',
    icon: <IconTextDocumentAlt width="18" height="19" viewBox="0 0 16 17" />,
    features: ['visitor_lead'],
    permissions: [
      'visitor_lead_module_manage_lead',
      'visitor_lead_module_branch_leads',
      'visitor_lead_module_all_leads',
    ],
  },
  {
    type: 'divider',
    label: 'Coaching',
    features: ['ielts_lead'],
    permissions: ['ielts_lead_module_manage_lead', 'ielts_lead_module_branch_leads', 'ielts_lead_module_all_leads'],
  },
  {
    type: 'item',
    label: 'Coaching Leads',
    route: '/ielts-leads',
    icon: <IconLayoutGrid size={20} color={XelaColor.Blue3} fill={XelaColor.Blue12} stroke={1.5} />,
    features: ['ielts_lead'],
    permissions: ['ielts_lead_module_manage_lead', 'ielts_lead_module_branch_leads', 'ielts_lead_module_all_leads'],
  },
  {
    type: 'divider',
    label: 'Insurance',
    features: ['insurance_lead'],
    permissions: [
      'insurance_lead_module_manage_lead',
      'insurance_lead_module_branch_leads',
      'insurance_lead_module_all_leads',
    ],
  },
  {
    type: 'item',
    label: 'Insurance Leads',
    route: '/insurance-leads',
    icon: <IconTextDocumentAlt width="18" height="19" viewBox="0 0 16 17" />,
    features: ['insurance_lead'],
    permissions: [
      'insurance_lead_module_manage_lead',
      'insurance_lead_module_branch_leads',
      'insurance_lead_module_all_leads',
    ],
  },
  {
    type: 'item',
    label: 'Policies Issued',
    route: '/insurance-leads/policies',
    icon: <IconSubtask viewBox="0 0 22 22" stroke={1.4} size={24} color={XelaColor.Blue3} fill={XelaColor.Blue12} />,
    features: ['insurance_lead'],
    permissions: [
      'insurance_lead_module_manage_lead',
      'insurance_lead_module_branch_leads',
      'insurance_lead_module_all_leads',
    ],
  },
  {
    type: 'item',
    label: 'Insurance Commission',
    route: '/insurance-leads/commissions',
    icon: <IconUserDollar stroke={1.4} size={18} color={XelaColor.Blue3} fill={XelaColor.Blue12} />,
    features: ['insurance_lead'],
    permissions: [
      'insurance_lead_module_manage_lead',
      'insurance_lead_module_branch_leads',
      'insurance_lead_module_all_leads',
    ],
  },

  {
    type: 'divider',
    features: ['lead_reports', 'b2b_hub', 'university_commissions', 'accounting'],
    permissions: [
      'report_module_access_lead_reports',
      'report_module_download_lead_reports',
      'report_module_access_counselling_reports',
      'report_module_download_counselling_reports',
      'report_module_access_admission_reports',
      'report_module_download_admission_reports',
      'report_module_access_admission_application_reports',
      'report_module_download_admission_application_reports',
      'report_module_access_visa_reports',
      'report_module_download_visa_reports',
      'report_module_access_follow_up_reports',
      'report_module_download_follow_up_reports',
      'report_module_access_ielts_reports',
      'report_module_download_ielts_reports',
      'report_module_access_visitor_visa_reports',
      'report_module_download_visitor_visa_reports',
      'report_module_access_payment_reports',
      'report_module_download_payment_reports',
      'b2b_module_access',
      'b2b_module_create_new_agent',
      'b2b_module_create_sub_agent_lead',
      'b2b_module_delete_sub_agent_lead',
      'b2b_module_delete_sub_agent_lead_application',
      'b2b_module_access_sub_agent_lead_documents',
      'b2b_module_edit_sub_agent_details',
      'b2b_module_delete_sub_agent',
      'b2b_module_access_lead_reports',
      'b2b_module_access_admission_reports',
      'b2b_module_access_visa_reports',
      'b2b_module_download_lead_reports',
      'b2b_module_download_admission_reports',
      'b2b_module_download_visa_reports',
      'university_commission_module_manage_commissions',
      'manage_accounting_payments',
      'approve_accounting_payments',
    ],
  },
  {
    type: 'item',
    label: 'Accounting',
    route: '/accounting',
    icon: <IconReportMoney stroke={1.4} size={18} color={XelaColor.Blue3} fill={XelaColor.Blue12} />,
    features: ['accounting'],
    permissions: ['manage_accounting_payments', 'approve_accounting_payments'],
  },
  {
    type: 'item',
    label: 'Reports',
    route: '/v2/reports',
    icon: <IconReport height="15" width="15" viewBox="0 0 15 15"></IconReport>,
    features: ['lead_reports'],
    permissions: [
      'report_module_access_lead_reports',
      'report_module_download_lead_reports',
      'report_module_access_counselling_reports',
      'report_module_download_counselling_reports',
      'report_module_access_admission_reports',
      'report_module_download_admission_reports',
      'report_module_access_admission_application_reports',
      'report_module_download_admission_application_reports',
      'report_module_access_visa_reports',
      'report_module_download_visa_reports',
      'report_module_access_follow_up_reports',
      'report_module_download_follow_up_reports',
      'report_module_access_ielts_reports',
      'report_module_download_ielts_reports',
      'report_module_access_visitor_visa_reports',
      'report_module_download_visitor_visa_reports',
      'report_module_access_payment_reports',
      'report_module_download_payment_reports',
    ],
  },
  {
    type: 'item',
    label: 'B2B Hub',
    route: '/b2b',
    icon: <IconB2BHub height="15" width="16" viewBox="0 0 15 16"></IconB2BHub>,
    features: ['b2b_hub'],
    permissions: [
      'b2b_module_access',
      'b2b_module_create_new_agent',
      'b2b_module_create_sub_agent_lead',
      'b2b_module_delete_sub_agent_lead',
      'b2b_module_delete_sub_agent_lead_application',
      'b2b_module_access_sub_agent_lead_documents',
      'b2b_module_edit_sub_agent_details',
      'b2b_module_delete_sub_agent',
      'b2b_module_access_lead_reports',
      'b2b_module_access_admission_reports',
      'b2b_module_access_visa_reports',
      'b2b_module_download_lead_reports',
      'b2b_module_download_admission_reports',
      'b2b_module_download_visa_reports',
    ],
  },
  {
    type: 'item',
    label: 'Commissions',
    route: '/university-commissions',
    icon: <IconCoin size={18} stroke={1.4} color={XelaColor.Blue3}></IconCoin>,
    features: ['university_commissions'],
    permissions: ['university_commission_module_manage_commissions'],
  },
  {
    type: 'divider',
    features: ['tasks', 'followups'],
    permissions: [
      'task_module_manage_tasks',
      'task_module_edit_task',
      'task_module_complete_task',
      'task_module_delete_comment',
      'task_module_edit_comment',
      'task_module_delete_task',
      'follow_up_module_follow_up_management',
      'follow_up_module_edit_follow_up',
      'follow_up_module_delete_follow_up',
    ],
  },
  {
    type: 'item',
    label: 'Tasks',
    route: '/tasks',
    icon: <IconListDetails stroke={1.5} color={XelaColor.Blue3}></IconListDetails>,
    features: ['tasks'],
    permissions: ['task_module_manage_tasks'],
  },
  {
    type: 'item',
    label: 'Follow Ups',
    route: '/follow-ups',
    icon: <IconSubtask stroke={1.5} color={XelaColor.Blue3}></IconSubtask>,
    features: ['followups'],
    permissions: [
      'follow_up_module_follow_up_management',
      'follow_up_module_edit_follow_up',
      'follow_up_module_delete_follow_up',
    ],
  },
  {
    type: 'grow',
  },
  {
    type: 'item',
    label: 'Swift AI',
    route: '/swift-ai',
    icon: <IconRobot stroke={1.4} color={XelaColor.Blue3}></IconRobot>,
    features: ['swift_ai'],
    permissions: ['swift_ai_module_access'],
  },
  {
    type: 'item',
    label: 'Helpdesk',
    route: '/helpdesk',
    icon: <IconHelp stroke={1.4} color={XelaColor.Blue3}></IconHelp>,
    features: ['*'],
    permissions: ['*'],
  },
  {
    type: 'item',
    label: 'Settings',
    route: '/settings',
    icon: <IconSettings height="18" width="18"></IconSettings>,
    features: ['*'],
    permissions: ['*'],
  },
]
